import axios from 'axios'
import {getAccessToken} from './msal'
let axiox = axios.create({
  baseURL:'https://api.hunghau.holdings',
  timeout:90000
})

axiox.interceptors.request.use(
  config => {
    config.headers.Authorization = 'Bearer '+ sessionStorage.getItem('Access_Token');
    return config;
  },
  error => {
      Promise.reject(error)
  });


axiox.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    originalRequest.headers.Authorization = 'Bearer ' + getAccessToken();
    return axiox(originalRequest);
  }
  return Promise.reject(error);
});


export default axiox