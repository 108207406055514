import { combineReducers } from 'redux';
import errorReducer from './errorReducer'
import authReducer from './authReducer'
import contentReducer from './contentReducer'
import timesheetReducer from './timesheetReducer'

export default combineReducers({
  auth: authReducer,
  timesheet:timesheetReducer,
  contents:contentReducer,
  errors: errorReducer
});
