import axios from './axios'
import {
  LIST_MAPS,
  INSERT_MAPS,
  UPDATE_MAPS,
  LIST_RULES,
  INSERT_RULES,
  UPDATE_RULES
} from './types'

// >>>>>>>>>>>>>>> Maps Actions <<<<<<<<<<<<<<<<<<<

export const list_maps = ()=> dispatch =>{
  axios.get('/api/contents/maps').then(resu => dispatch({
    type:LIST_MAPS,
    payload:resu.data
  }))
}

export const instert_maps = (data) => dispatch => {
  axios.post('/api/contents/maps',data).then(resu => dispatch({
    type:INSERT_MAPS,
    payload:resu.data
  }))
}

export const update_maps = (data) => dispatch => {
  axios.put('/api/contents/maps',data).then(resu=> dispatch({
    type:UPDATE_MAPS,
    payload:resu.data
  }))
}

// >>>>>>>>>>>>>>> Rules Actions <<<<<<<<<<<<<<<<<<<

export const list_rules = ()=> dispatch =>{
  axios.get('/api/contents/rules').then(resu => dispatch({
    type:LIST_RULES,
    payload:resu.data
  }))
}

export const instert_rules = (data) => dispatch => {
  axios.post('/api/contents/rules',data).then(resu => dispatch({
    type:INSERT_RULES,
    payload:resu.data
  }))
}

export const update_rules = (data) => dispatch => {
  axios.put('/api/contents/rules',data).then(resu=> dispatch({
    type:UPDATE_RULES,
    payload:resu.data
  }))
}
