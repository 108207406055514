import React from 'react'

function UserLayout(props){
  return(
    <>
      {props.children}
    </>
  )
}

export default UserLayout