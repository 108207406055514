import React,{useEffect,useState} from 'react'
import {Table,Input,Space,Button} from 'antd'
import {useSelector,useDispatch} from 'react-redux'
import {list_IP,instert_IP,remove_IP} from '../../actions/timesheetAction'

function IPRuleModal(){
  const [data, setData] = useState({
    IPName:'',
    IP:''
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(list_IP())
  }, [])
  const columns = [
    {
      title:"IP",
      dataIndex: 'IP',
      key: 'IP'
    },{
      title:'IP Name',
      dataIndex: 'IPName',
      key: 'IPName'
    },{
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => dispatch(remove_IP(record))}>Delete</Button>
        </Space>
      )
    }
  ]
  const {IPRules} = useSelector(state => state.timesheet)
  return(
    <>
      <Table
        rowKey="_id"
        size="small"
        columns={columns} dataSource={IPRules}
      /> 
      <Input.Group compact>
        <Input placeholder="Nhập Tên" style={{ width: '45%' }} value={data.IPName} onChange={value => setData({...data,IPName:value.target.value})} />
        <Input.Search placeholder="Nhập IPv4" 
          enterButton="Thêm" style={{ width: '55%' }} 
          onChange={value => setData({...data,IP:value.target.value})} 
          onSearch={() => dispatch(instert_IP(data))}
        />
      </Input.Group>
    </>
  )
}

export default IPRuleModal