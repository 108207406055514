import * as msal from 'msal'

const myMSALObj = new msal.UserAgentApplication({
  auth: {
    clientId: "64108941-8476-4d57-8f86-a75ac1919844",
    authority: "https://login.microsoftonline.com/65ae636f-3f68-47d5-86da-672f582a27d7",
    redirectUri:'https://notes.hunghau.holdings/azure'
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  }
});

const option = {scopes: ["api://64108941-8476-4d57-8f86-a75ac1919844/User","offline_access"]}
const optionRefesh = {
  scopes: ["api://64108941-8476-4d57-8f86-a75ac1919844/User","offline_access"],
  forceRefresh: true
}

export const getAccessToken = () => {
  if (myMSALObj.getAccount()) {
    myMSALObj.acquireTokenSilent(optionRefesh)
      .then(response => {
        sessionStorage.setItem("Access_Token", response.accessToken);
        sessionStorage.setItem("ExpireIn", response.expiresOn);
        return response.accessToken
      })
  }
}

export const SignIn = (history) =>{
  myMSALObj.loginPopup(option)
  .then(loginResponse => {
    if (myMSALObj.getAccount()) {
      console.log(myMSALObj.getAccount())
      myMSALObj.acquireTokenSilent(option) 
      .then(response => {
        sessionStorage.setItem("Access_Token", response.accessToken);
        sessionStorage.setItem("ExpireIn", response.expiresOn);
        history.push('/dashboard')
      })
    }
  }).catch(error => {
    console.error(error);
  });
}