import React,{useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import {syncUser,syncStatusClosed} from '../actions/userActions'
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AzureSync(){
  const query = useQuery()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(syncUser(query.get('code')))
  }, [dispatch])
  const {syncStatus} = useSelector(state => state.auth)
  useEffect(() => {
    if(syncStatus){
      dispatch(syncStatusClosed())
      window.close();
    }
  }, [syncStatus])
  return(
    <>
      Percent Complete
    </>
  )
}
export default AzureSync