import React,{useEffect} from 'react'
import {Layout} from 'antd'
import {useDispatch} from 'react-redux'
import {getUserInfomation} from '../actions/userActions'

const {Content} = Layout

function Dashboard(){
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUserInfomation())
  }, [dispatch])
  return(
    <Content>
      123
    </Content>
  )  
}

export default Dashboard