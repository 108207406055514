import React,{useState,useEffect,useRef} from 'react'
import {PageHeader, Button, Table, Input, Space,Modal,Upload,Form,Select,DatePicker} from 'antd'
import Highlighter from 'react-highlight-words';
import { SearchOutlined,UploadOutlined,DownloadOutlined,SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import * as XLSX from 'xlsx'
import {useDispatch,useSelector} from 'react-redux'
import {setUpdateUsers,loadUsers,update_users,instert_users} from '../actions/userActions'
import {list_maps} from '../actions/contentsAction'
import _ from 'lodash'

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
function UsersPage(){
  const [id, setID] = useState('')
  const [OpenModal, setOpenModal] = useState(false)
  const [selectedRowKeys, setselectedRowKeys] = useState([])
  const [searchText, setsearchText] = useState('')
  const [searchedColumn, setsearchedColumn] = useState('')
  const searchInput = useRef(null)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadUsers())
    dispatch(list_maps())
  }, [dispatch])
  
  const {users} = useSelector(state => state.auth)
  const {Maps} = useSelector(state => state.contents)
  const onSelectChange = selected =>{
    setselectedRowKeys(selected)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.focus(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0])
    setsearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setsearchText('')
  };

  const columns = [
    {
      title: 'Display Name',
      dataIndex: 'DisplayName',
      key: 'DisplayName',
      ...getColumnSearchProps('DisplayName'),
    },
    {
      title: 'Full Name',
      dataIndex: 'FullName',
      key: 'FullName',
      ...getColumnSearchProps('FullName'),
    },
    {
      title: 'Gender',
      dataIndex: 'Gender',
      key: 'Gender',
      ...getColumnSearchProps('Gender'),
    },
    {
      title: 'Birthday',
      dataIndex: 'Birthday',
      key: 'Birthday',
      render:(value) => dayjs(value).format('l')
    },
    {
      title: 'Position',
      dataIndex: 'Position',
      key: 'Position',
      ...getColumnSearchProps('Position'),
    },
    {
      title: 'Department',
      dataIndex: 'Department',
      key: 'Department',
      ...getColumnSearchProps('Department'),
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      ...getColumnSearchProps('Email'),
    },
    {
      title: 'Phone Number',
      dataIndex: 'PhoneNumber',
      key: 'PhoneNumber',
      ...getColumnSearchProps('PhoneNumber'),
    },
    {
      title: 'Company Address',
      dataIndex: ['CompanyAddress','Name'],
      key: 'CompanyAddress',
      ...getColumnSearchProps('CompanyAddress'),
    }
  ]
  const [formData] = Form.useForm()
  
  return(
    <PageHeader
      title="User Manager"
      extra={[
        <span>
          <Button key='0' onClick={()=> window.open(`https://login.microsoftonline.com/65ae636f-3f68-47d5-86da-672f582a27d7/oauth2/v2.0/authorize?client_id=64108941-8476-4d57-8f86-a75ac1919844&response_type=code&redirect_uri=http://localhost:3000/azuresync&response_mode=query&scope=https://graph.microsoft.com/User.Read.All&state=12345`, "_blank")}>
            <SyncOutlined />
            Sync Data
          </Button>
        </span>
        ,
        <Upload
          showUploadList={false}
          beforeUpload={(file) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
              const bstr = evt.target.result;
              const wb = XLSX.read(bstr, {type:'binary'});
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              const data = XLSX.utils.sheet_to_json(ws, {header:1}).map(result => {
                console.log(result)
                return({
                  FullName:result[0],
                  Email:result[1],
                  JobTitle:result[2],
                  Department:result[3],
                  PhoneNumber:result[4],
                  Company:result[5],
                  ManagerEmail:result[6]
                })
              })
              dispatch(setUpdateUsers({users:data}))
            }
            reader.readAsBinaryString(file);
            return false
          }}
        >
          <Button key="1">
            <UploadOutlined /> Import data
          </Button>
        </Upload>,
        <span>
          <Button key='2'>
            <DownloadOutlined />
            Export data
          </Button>
        </span>,
        <span>
          <Button key='3'>
            Delete items
          </Button>
        </span>,
        <span>
          <Button key='3'>
            Update items
          </Button>
        </span>
      ]}
    >
      <Table 
        rowSelection= {rowSelection}
        rowKey="_id"
        columns={columns} 
        dataSource={users} 
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: event => {
              console.log(record)
              setID(record._id)
              formData.setFieldsValue({
                DisplayName:record.DisplayName,
                FullName:record.FullName,
                Gender:record.Gender,
                Position:record.Position,
                Department:record.Department,
                Birthday:dayjs(record.Birthday),
                PhoneNumber:record.PhoneNumber,
                Email:record.Email,
                Company:record.Company,
                Manager:record.Manager,
                CompanyAddress:_.isEmpty(record.CompanyAddress)?'':record.CompanyAddress._id
              })
              setOpenModal(!OpenModal)
            }
          };
        }}
      />
      <Modal
        width={960}
        title="Thông tin nhân sự"
        visible={OpenModal}
        onOk={() => {
          if(id){
            dispatch(update_users({
              id,
              ...formData.getFieldValue()
            }))
          } else {
            dispatch(instert_users(formData.getFieldValue()))
          }
          setOpenModal(!OpenModal)
        }}
        onCancel={() => {
          setID('')
          setOpenModal(!OpenModal)
        }}
      >
        <Form {...layout} form={formData} name="basic">
          <Form.Item name="DisplayName" label="Tên hiển thị">
            <Input/>
          </Form.Item>
          <Form.Item name="Email" label="Email">
            <Input/>
          </Form.Item>
          <Form.Item name="FullName" label="Tên đầy đủ">
            <Input/>
          </Form.Item>
          <Form.Item name="Gender" label="Giới tính">
          <Select>
            <Select.Option value="Nam">Nam</Select.Option>
            <Select.Option value="Nữ">Nữ</Select.Option>
            <Select.Option value="Khác">Khác</Select.Option>
          </Select>
          </Form.Item>
          <Form.Item name="Birthday" label="Sinh nhật">
            <DatePicker format="DD-MM-YYYY"/>
          </Form.Item>
          <Form.Item name="Position" label="Chức vụ">
            <Input/>
          </Form.Item>
          <Form.Item name="Department" label="Đơn vị/Phòng ban">
            <Input/>
          </Form.Item>
          <Form.Item name="Manager" label="Người quản lý">
            <Input/>
          </Form.Item>
          <Form.Item name="PhoneNumber" label="Số điện thoại">
            <Input/>
          </Form.Item>
          <Form.Item name="Avatar" label="Ảnh đại diện">
            <Input/>
          </Form.Item>
          <Form.Item name="Company" label="Công ty">
            <Input/>
          </Form.Item>
          <Form.Item name="CompanyAddress" label="Địa chỉ công ty">
            <Select>
              {Maps.map(map => (
                <Select.Option key={map._id} value={map._id}>{map.Name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </PageHeader>
  )
}

export default UsersPage