import React ,{useState} from 'react'
import {PageHeader, Button, Table, Modal,Input,Form} from 'antd'
import {useSelector,useDispatch} from 'react-redux'
import {DatePicker} from '../components'
import dayjs from 'dayjs'
const defaultColum=[
  {
    title: 'STT',
    dataIndex: ['UID','FullName'],
    key: 'FullName'
  },
  {
    title: 'Họ và tên',
    dataIndex: ['UID','FullName'],
    key: 'FullName'
  },
  {
    title: 'Phòng ban',
    dataIndex: ['UID','FullName'],
    key: 'FullName'
  },

]
function TimesheetReport(){
  const [RangeDate, setRangeDate] = useState([
    dayjs().hour(0).minute(0),dayjs().hour(23).minute(59)
  ])
  const dispatch = useDispatch()
  const {reports} = useSelector(state => state.timesheet)
  const [colum, setColum] = useState(defaultColum)
  return(
    <PageHeader
      title="Bảng báo cáo chấm công"
      extra={[
        <DatePicker.RangePicker 
          key={4}
          format="DD/MM/YYYY"
          defaultValue={RangeDate}
          onChange={(value) => setRangeDate(value)}
        />,
        <Button key="2" onClick={() => console.log('1')}>IP Rules</Button>,
        <Button key="3">Export</Button>,
      ]}
    >
      <Table
       columns={colum}
       dataSource={reports} 
      />
    </PageHeader>
  )
}

export default TimesheetReport