import { LIST_IP,ADD_IP,REMOVE_IP,UPDATE_IP,LIST_CHECKPOINT } from '../actions/types';

const initialState = {
  IPRules:[],
  CheckPoint:[],
  reports:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_IP:
      return{
        ...state,
        IPRules:action.payload
      }
    case ADD_IP:
      return{
        ...state,
        IPRules: [...state.IPRules,action.payload]
      }
    case REMOVE_IP:
      return{
        ...state,
        IPRules: state.IPRules.filter(x => x.IP !== action.payload.IP)
      }
    case UPDATE_IP:
      return{
        ...state,
        IPRules: state.IPRules.map(x => x.IP === action.payload.IP ? action.payload : x )
      }
    case LIST_CHECKPOINT:
      return{
        ...state,
        CheckPoint:action.payload
      }
    default:
      return state;
  }
}
