import { LIST_MAPS,INSERT_MAPS,UPDATE_MAPS,LIST_RULES,INSERT_RULES,UPDATE_RULES} from '../actions/types';

const initialState = {
  Maps:[],
  Rules:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_MAPS:
      return {
        ...state,
        Maps:action.payload
      };
    case INSERT_MAPS:
      return{
        ...state,
        Maps:[action.payload,...state.Maps]
      }
    case UPDATE_MAPS:
      return{
        ...state,
        Maps:state.Maps.map(mps => mps._id === action.payload._id ? action.payload : mps)
      }
    case LIST_RULES:
      return {
        ...state,
        Rules:action.payload
      };
    case INSERT_RULES:
      return{
        ...state,
        Rules:[action.payload,...state.Rules]
      }
    case UPDATE_RULES:
      return{
        ...state,
        Rules:state.Rules.map(rule => rule._id === action.payload._id ? action.payload : rule)
      }
    default:
      return state;
  }
}
