import React , {useState} from 'react'
import { Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  TrophyOutlined,
  FileDoneOutlined,
  SafetyCertificateOutlined,
  CompassOutlined,
  DashboardOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import {useLocation,useHistory} from 'react-router-dom'

const { Header, Sider, Content } = Layout;



function AdminLayout(props){
  const [collapsed, setCollapsed] = useState(false)
  const toggle = () => setCollapsed(!collapsed)
  const history = useHistory();
  let location = useLocation();
  console.log(location.pathname);
  return(
    <Layout style={{minHeight:'100vh'}}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
          <Menu.Item key="/dashboard" icon={<DashboardOutlined />} onClick={()=>history.push('/dashboard')}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="/users" icon={<UserOutlined />} onClick={()=>history.push('/users')}>
            Users
          </Menu.Item>
          <Menu.SubMenu key="sub1" icon={<FileDoneOutlined />} title="Bảng chấm công">
            <Menu.Item key="/timesheet" onClick={()=>history.push('/timesheet')}>Bảng chi tiết</Menu.Item>
            <Menu.Item key="/timesheetreport" onClick={()=>history.push('/timesheetreport')}>Báo cáo chấm công</Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/terms" icon={<SafetyCertificateOutlined />} onClick={()=>history.push('/terms')}>
            Nội quy/ Quy định
          </Menu.Item>
          <Menu.Item key="/maps" icon={<CompassOutlined />} onClick={()=>history.push('/maps')}>
            Cơ sở
          </Menu.Item>
          <Menu.Item key="/events" icon={<TrophyOutlined />} onClick={()=>history.push('/events')}>
            Sự kiện
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
        </Header>
        <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
      </Layout>
      
    </Layout>
  )
}

export default AdminLayout