export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const GET_USER_INFOMATION = 'GET_USER_INFOMATION'
export const LIST_USERS = 'LIST_USERS'

export const INSERT_MAPS = "INSERT_MAPS"
export const LIST_MAPS = "LIST_MAPS"
export const UPDATE_MAPS = "UPDATE_MAPS"

export const LIST_RULES = "LIST_RULES"
export const INSERT_RULES = "INSERT_RULES"
export const UPDATE_RULES = "UPDATE_RULES"
export const UPDATE_USER =  "UPDATE_USER"
export const INSERT_USER = "INSERT_USER"

export const LIST_IP_RULES = "LIST_IP_RULES"

export const LIST_IP = "LIST_IP"
export const ADD_IP = "ADD_IP"
export const REMOVE_IP = "REMOVE_IP"
export const UPDATE_IP = "UPDATE_IP"
export const LIST_CHECKPOINT = "LIST_CHECKPOINT"
export const SYNC_STATUS = "SYNC_STATUS"
export const SYNC_STATUS_CLOSED = "SYNC_STATUS_CLOSED"