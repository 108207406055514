import axios from './axios'
import {
  GET_USER_INFOMATION,
  LIST_USERS,
  UPDATE_USER,
  INSERT_USER,
  SYNC_STATUS,
  SYNC_STATUS_CLOSED
} from './types'

export const getUserInfomation = () => dispatch => {
  axios
    .get('/me',{baseURL:'https://graph.microsoft.com/v1.0'})
    .then(res => {
      dispatch({
        type:GET_USER_INFOMATION,
        payload:res.data
      })
    })
    .catch(err=>{
      console.log(err)
    })
}

export const setUpdateUsers = (data) => dispatch => {
  axios.post('/api/users/update',data).then(resu => 
    dispatch({
      type:LIST_USERS,
      payload:resu.data
    }))
    .catch(err=>console.log(err))
}

export const update_users = (data) => dispatch => {
  axios.put('/api/users/user',data).then(resu => 
    dispatch({
      type:UPDATE_USER,
      payload:resu.data
    }))
    .catch(err=>console.log(err))
}

export const instert_users = (data) => dispatch => {
  axios.post('/api/users/user',data).then(resu => 
    dispatch({
      type:INSERT_USER,
      payload:resu.data
    }))
    .catch(err=>console.log(err))
}

export const syncUser = (code) => dispatch => {
  axios.post('/api/users/sync',{code},{timeout:0}).then(result => dispatch({
    type:SYNC_STATUS,
    payload:result.data
  }))
}

export const syncStatusClosed = () => dispatch => {
  dispatch({
    type:SYNC_STATUS_CLOSED
  })
}

export const loadUsers = () => dispatch=>{
  axios.get('/api/users/list')
  .then(resu => {
    console.log(resu.data)
    dispatch({
      type:LIST_USERS,
      payload:resu.data
    })
  })
}