import React,{useState,useEffect} from 'react'
import {PageHeader, Button, Table, Modal,Input,InputNumber,Form} from 'antd'
import {instert_maps,list_maps,update_maps} from '../actions/contentsAction'
import {useSelector,useDispatch} from 'react-redux'

const columns = [
  {
    title: 'Tên cơ sở/ đơn vị',
    dataIndex: 'Name',
    key: 'Name'
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'DiaChi',
    key: 'DiaChi'
  },
  {
    title: 'Latitude',
    dataIndex: 'Latitude',
    key: 'Latitude'
  },
  {
    title: 'Longitude',
    dataIndex: 'Longitude',
    key: 'Longitude'
  }
]
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
function TermsScreen(){

  const [selectedRowKeys, setselectedRowKeys] = useState([])
  const [ID, setID] = useState('')
  const [OpenModal, setOpenModal] = useState(false)
  const [formData] = Form.useForm()
  const dispatch = useDispatch()
  const {Maps} = useSelector(state => state.contents)
  useEffect(() => {
    dispatch(list_maps())
  }, [dispatch])
  const onSelectChange = selected =>{
    setselectedRowKeys(selected)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  

  return(
    <PageHeader
      title="Danh sách các cơ sở"
      extra={[
        <Button key="1" onClick={()=>{
          setID('')
          setOpenModal(!OpenModal)
        }}
          >Insert new</Button>,
        <Button key="2">Delete</Button>,
        <Button key="3">Primary</Button>,
      ]}
    >
      <Table 
        rowSelection={rowSelection}
        rowKey="_id"
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: event => {
              setID(record._id)
              formData.setFieldsValue({
                Name:record.Name,
                Address:record.DiaChi,
                Latitude:record.Latitude,
                Longitude:record.Longitude
              })
              setOpenModal(!OpenModal)
            }
          };
        }}
        columns={columns} 
        dataSource={Maps} 
      /> 
      <Modal
        title="Thông tin cơ sở/đơn vị"
        visible={OpenModal}
        onOk={() => {
          if(ID){
            dispatch(update_maps({
              id:ID,
              ...formData.getFieldValue()
            }))
          } else {
            dispatch(instert_maps(formData.getFieldValue()))
          }
          
          setOpenModal(!OpenModal)
        }}
        onCancel={() => {
          setID('')
          setOpenModal(!OpenModal)
        }}
      >
        <Form {...layout} form={formData} name="basic">
          <Form.Item name="Name" label="Tên cơ sở/ đơn vị">
            <Input/>
          </Form.Item>
          <Form.Item name="Address" label="Địa chỉ">
            <Input/>
          </Form.Item>
          <Form.Item name="Latitude" label="Latitude">
            <InputNumber/>
          </Form.Item>
          <Form.Item name="Longitude" label="Longitude">
            <InputNumber/>
          </Form.Item>
        </Form>
        
      </Modal>
    </PageHeader>
  )
}

export default TermsScreen