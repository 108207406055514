import axios from './axios'
import {
  LIST_IP,
  ADD_IP,
  REMOVE_IP,
  UPDATE_IP,
  LIST_CHECKPOINT
} from './types'


export const list_IP = () => dispatch => {
  axios.get('/api/checkpoint/iprules').then(checkpoint => 
    dispatch({
      type:LIST_IP,
      payload:checkpoint.data
  }))
}

export const instert_IP = (data) => dispatch => {
  axios.post('/api/checkpoint/iprules',data).then(checkpoint => 
    dispatch({
      type:ADD_IP,
      payload:checkpoint.data
  }))
}

export const update_IP = (data) => dispatch => {
  axios.put('/api/checkpoint/iprules',data).then(checkpoint => 
    dispatch({
      type:UPDATE_IP,
      payload:checkpoint.data
  }))
}

export const remove_IP = (data) => dispatch => {
  axios.delete('/api/checkpoint/iprules',{data}).then(checkpoint => 
    dispatch({
      type:REMOVE_IP,
      payload:checkpoint.data
  }))
}

export const list_CheckPoint = (data) => dispatch => {
  axios.post('/api/checkpoint/detail',data).then(result =>
    dispatch({
      type:LIST_CHECKPOINT,
      payload:result.data
    }))
}

export const list_CheckPointReport = (data) => dispatch => {
  axios.post('/api/checkpoint/reports',data).then(result =>
    dispatch({
      type:LIST_CHECKPOINT,
      payload:result.data
    }))
}