import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import AdminLayout from './layout/AdminLayout'
import UserLayout from './layout/UserLayout'

import LoginPage from './pages/LoginPage'
// import NotFoundPage from './pages/NotFoundPage'
import EventsPage from './pages/EventsPage'
import TimesheetPage from './pages/TimesheetPage'
import DashboardPage from './pages/DashboardPage'
import TermsPage from './pages/TermsPage'
import UsersPage from './pages/UsersPage'
import MapsPage from './pages/MapsPage'
import AzureSync from './pages/AzureSync'
import TimesheetReportPage from './pages/TimesheetReportPage'
import MapKit from './pages/MapKit'

import 'antd/dist/antd.css';
import './App.css'
import store from './store'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.locale('vi')
dayjs.extend(localizedFormat)

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <AppRoute exact path="/login" component={LoginPage} layout={UserLayout}/>
          <AppRoute exact path="/mapkit" component={MapKit} layout={UserLayout}/>
        </Switch>
        <Switch>
          <AppRoute exact path='/dashboard' component={DashboardPage} layout={AdminLayout}/>
          <AppRoute exact path='/terms' component={TermsPage} layout={AdminLayout}/>
          <AppRoute exact path='/maps' component={MapsPage} layout={AdminLayout}/>
          <AppRoute exact path='/timesheet' component={TimesheetPage} layout={AdminLayout}/>
          <AppRoute exact path='/events' component={EventsPage} layout={AdminLayout}/>
          <AppRoute exact path='/users' component={UsersPage} layout={AdminLayout}/>
          <AppRoute exact path='/azuresync' component={AzureSync} layout={UserLayout} />
          <AppRoute exact path='/timesheetreport' component={TimesheetReportPage} layout={AdminLayout}/>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
