import React from 'react'
import {Row, Button} from 'antd'
import {useHistory} from 'react-router-dom'
import {SignIn} from '../actions/msal'

function LoginPage(){
  const history = useHistory()
  return(
    <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh',backgroundImage:`url(${require('../images/background-intro.jpg')})`}}>
      <Button type="primary" onClick={()=> SignIn(history)}>
        Đăng nhập bằng tài khoảng Email
      </Button>
    </Row>
  )
}

export default LoginPage