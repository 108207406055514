import { 
  GET_USER_INFOMATION , LIST_USERS,INSERT_USER,UPDATE_USER,SYNC_STATUS,SYNC_STATUS_CLOSED
} from '../actions/types';
var _ = require('lodash')
const initialState = {
  userInfo:{},
  isAuthenticated:false,
  users:[],
  syncStatus:false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INFOMATION:
      return {
        ...state,
        userInfo:action.payload,
        isAuthenticated:_.isEmpty(action.payload)
      };
    case LIST_USERS:
      return{
        ...state,
        users:action.payload
      }
    case INSERT_USER:
      return{
        ...state,
        users:[action.payload,...state.users]
      }
    case UPDATE_USER:
      return{
        ...state,
        users:state.users.map(x => x._id === action.payload._id ? action.payload : x)
      }
    case SYNC_STATUS:
      return{
        ...state,
        syncStatus: true
      }
    case SYNC_STATUS_CLOSED:
      return{
        ...state,
        syncStatus:false
      }
    default:
      return state;
  }
}
