import React,{useState,useEffect} from 'react'
import {PageHeader, Button, Table, Modal,Input,Space} from 'antd'
import {useSelector,useDispatch} from 'react-redux'
import {DatePicker} from '../components'
import IPRuleModal from '../components/TimeSheet/IPRuleModal'
import {list_CheckPoint} from '../actions/timesheetAction'
import dayjs from 'dayjs'
import GoogleMapReact from 'google-map-react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const columns = [
  {
    title: 'Họ và tên',
    dataIndex: ['UID','FullName'],
    key: 'FullName'
  },
  {
    title: 'Phong Ban',
    dataIndex: ['UID','Department'],
    key: 'Department'
  },
  {
    title: 'IP',
    dataIndex: 'IP',
    key: 'IP'
  },
  { 
    title: 'Latitude',
    dataIndex: 'Latitude',
    key: 'Latitude'
  },
  {
    title: 'Longitude',
    dataIndex: 'Longitude',
    key: 'Longitude'
  },
  {
    title: 'Ngày',
    dataIndex: 'Ngay',
    key: 'Ngay'
  },
  {
    title: 'Thang',
    dataIndex: 'Thang',
    key: 'Thang'
  },
  {
    title: 'Năm',
    dataIndex: 'Nam',
    key: 'Nam'
  },
  {
    title: 'Giờ vào',
    dataIndex: 'CheckIn',
    key: 'CheckIn',
    render:(value) => dayjs(value).format('LT')
  },
  {
    title: 'Giờ ra',
    dataIndex: 'CheckOut',
    key: 'CheckOut',
    render:(value) => dayjs(value).format('LT')
  }
]

function TimeSheetScreen(){
  const [RangeDate, setRangeDate] = useState([
    dayjs().hour(0).minute(0),dayjs().hour(23).minute(59)
  ])
  const [IPModal, setIPModal] = useState(false)
  const [MapModal, setMapModal] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    columns.push({
      title: 'Action',
      key: 'action',
      render: (any, Item) => (
        <Space size="middle">
          <a href="javascript:;" onClick={()=>setMapModal(x => !x)}>Vị trí chấm công</a>
          <a className="ant-dropdown-link">
            Xác thực
          </a>
        </Space>
      )
    })
  }, [])
  const exportToCSV = (csvData, fileName) => {
    let arrdata = [['CÔNG TY CỔ PHẦN PHÁT TRIỂN HÙNG HẬU'],['Địa Chỉ: 1004A Âu Cơ, Phường Phú Trung, Quận Tân Phú, Tp Hồ Chí Minh'],['Điện Thoại: (+84) 8 38 604 999 - Fax: (+84) 8 38 604 666'],['BÁO CÁO CHẤM CÔNG TỔNG HỢP'],['TỪ NGÀY: 01/01/2020 - ĐẾN NGÀY: 31/01/2020'],['STT','Họ và Tên','Phòng ban','Ngày trong tháng']]
    let currentDay = RangeDate[0]
    let DateRow = ['','','']
    while (currentDay.isBefore(RangeDate[1])) {
      DateRow.push(currentDay.format('DD.dd'))
      currentDay = currentDay.add(1,'days')
    }
    let merge = [
      { s: { r: 3, c: 0 }, e: { r: 3, c: DateRow.length-1 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: DateRow.length-1 } },
      { s: { r: 5, c: 0 }, e: { r: 6, c: 0 } },
      { s: { r: 5, c: 1 }, e: { r: 6, c: 1 } },
      { s: { r: 5, c: 2 }, e: { r: 6, c: 2 } },
      { s: { r: 5, c: 3 }, e: { r: 5, c: DateRow.length-1 } },
    ]
    arrdata.push(DateRow)
    let ObjectZ = []
    csvData.forEach(element => {
      const index = ObjectZ.findIndex(x => x.id === element.UID._id)
      if(index<0){
        ObjectZ.push({
          id:element.UID._id,
          HovaTen:element.UID.FullName,
          PhongBan:element.UID.Department,
          [dayjs(element.CheckIn).format('DD.dd')]:[dayjs(element.CheckIn).format('hh:mm') ,dayjs(element.CheckOut).format('hh:mm')]
        })
      } else {
        const item = ObjectZ[index] 
        item[dayjs(element.CheckIn).format('DD.dd')]=[dayjs(element.CheckIn).format('hh:mm') ,dayjs(element.CheckOut).format('hh:mm')]
        ObjectZ[index] = item
      }
    });
    ObjectZ.map((obj,index) => {
      const FirstArray = [index,obj.HovaTen,obj.PhongBan]
      const SecondArray = []
      Object.keys(obj).forEach(element => {
        const j = DateRow.indexOf(element)
        if(j>0 && Array.isArray(obj[element])){
          FirstArray[j] = obj[element][0]
          SecondArray[j] = obj[element][1]
        }
      });
      if(FirstArray.length>0){
        merge.push({ s: { r: 6+(2*index)+1, c: 0 }, e: { r: 6+(2*index)+2, c: 0 } },{ s: { r: 6+(2*index)+1, c: 1 }, e: { r: 6+(2*index)+2, c: 1 } },{ s: { r: 6+(2*index)+1, c: 2 }, e: { r: 6+(2*index)+2, c: 2 } })
        arrdata.push(FirstArray)
        arrdata.push(SecondArray)
      }
    })
    const ws = XLSX.utils.aoa_to_sheet(arrdata);
    ws["!merges"] = merge;
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  useEffect(() => {
    dispatch(list_CheckPoint({start:RangeDate[0].toISOString(),stop:RangeDate[1].toISOString()}))
  }, [RangeDate])

  const {CheckPoint} = useSelector(state => state.timesheet)
  return(
    <PageHeader
      title="Bảng chấm công"
      extra={[
        <DatePicker.RangePicker 
          key={4}
          format="DD/MM/YYYY"
          defaultValue={RangeDate}
          onChange={(value) => setRangeDate(value)}
        />,
        <Button key="2" onClick={() => setIPModal(x => !x)}>IP Rules</Button>,
        <Button key="3" onClick={()=>exportToCSV(CheckPoint,'fileName')}>Export</Button>,
      ]}
    >
      <Table
       columns={columns} dataSource={CheckPoint} /> 
      <Modal
        title="IP Rules"
        visible={IPModal}
        onCancel={() => {
          setIPModal(x => !x)
        }}
      >
        <IPRuleModal />
      </Modal>
      <Modal 
        title="Maps"
        visible={MapModal}
        onCancel={() => {
          setMapModal(x => !x)
        }}
      >
        <div style={{height:400,width:'100%'}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDnetgcUz1MLL89voBE5aoR7ywUE9jxJAU' }}
            defaultCenter={{
              lat: 59.95,
              lng: 30.33
            }}
            defaultZoom={11}
          >
            
          </GoogleMapReact>
        </div>
        
      </Modal>
    
    </PageHeader>
  )
}

export default TimeSheetScreen