import React,{useState,useEffect} from 'react'
import {PageHeader, Button, Table, Modal,Input,Form} from 'antd'
import {useSelector,useDispatch} from 'react-redux'
import {list_rules,instert_rules,update_rules} from '../actions/contentsAction'

const columns = [
  {
    title: 'Ten',
    dataIndex: 'Name',
    key: 'Name'
  },
  {
    title: 'Link',
    dataIndex: 'Link',
    key: 'Link'
  }
]
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
function TermsScreen(){
  const [selectedRowKeys, setselectedRowKeys] = useState([])
  const [ID, setID] = useState('')
  const [formData] = Form.useForm()
  const [OpenModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()
  const onSelectChange = selected =>{
    setselectedRowKeys(selected)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  useEffect(() => {
    dispatch(list_rules())
  }, [dispatch])
  const {Rules} = useSelector(state => state.contents)
  return(
    <PageHeader
      title="Quy định/ Nội quy"
      extra={[
        <Button key="1" onClick={()=>{
          setID('')
          setOpenModal(!OpenModal)
        }}>Insert new</Button>,
        <Button key="2">Do some thing</Button>,
        <Button key="3">Primary</Button>,
      ]}
    >
      <Table
        rowSelection={rowSelection}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: event => {
              setID(record._id)
              formData.setFieldsValue({
                Name:record.Name,
                Link:record.Link
              })
              setOpenModal(!OpenModal)
            }
          };
        }}
        rowKey="_id"
       columns={columns} dataSource={Rules} /> 
      <Modal
        title="Thông tin nội quy/ quy định"
        visible={OpenModal}
        onOk={() => {
          if(ID){
            dispatch(update_rules({
              id:ID,
              ...formData.getFieldValue()
            }))
          } else {
            dispatch(instert_rules(formData.getFieldValue()))
          }
          
          setOpenModal(!OpenModal)
        }}
        onCancel={() => {
          setID('')
          setOpenModal(!OpenModal)
        }}
      >
        <Form {...layout} form={formData} name="basic">
          <Form.Item name="Name" label="Tên">
            <Input/>
          </Form.Item>
          <Form.Item name="Link" label="Link">
            <Input/>
          </Form.Item>
        </Form>
      </Modal>
    </PageHeader>
  )
}

export default TermsScreen